import React, { useState} from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faFaceAngry } from '@fortawesome/free-regular-svg-icons';

import './styleCSS/Home.css'
import './styleCSS/fonts_variables.css'
import img1a from './images/meqq1.png'
import img1b from './images/meqq1bgc.png'
import img2a from './images/meqq2.png'
import img2b from './images/meqq2bgc.png'
import img3a from './images/meqq3.png'
import img3b from './images/meqq3bgc.png'
import img4a from './images/meqq4.png'
import img4b from './images/meqq4bgc.png'
import img5a from './images/meqq5.png'
import img5b from './images/meqq5bgc.png'
import img6a from './images/meqq6.png'
import img6b from './images/meqq6bgc.png'
import img7a from './images/meqq7.png'
import img7b from './images/meqq7bgc.png'
import img8a from './images/meqq8.png'
import img8b from './images/meqq8bgc.png'



function Home() {
  
    const [currentQuestion, setCurrentQuestion] = useState('start');
    const [questionHistory, setQuestionHistory] = useState([]);

  
    // Obiekt reprezentujący drzewo konwersacji
    const conversationTree = {
      start: {
        question: 'WELCOME!',
        imagesSet: 'set1',
        answers: [
          { text: 'HI! NICE TO MEET YOU!', nextQuestion: 'question2' },
          { text: 'HELLO!', nextQuestion: 'question2' },
          { text: 'GOODBY...',
            icon: faFaceAngry,
            nextQuestion: 'question3' },
        ],
      },
      question2: {
        question: 'HOW ARE YOU?',
        imagesSet: 'set2',
        answers: [
            { text: 'VERY WELL!', nextQuestion: 'question4' },
            { text: 'GOOD, THANKS!', nextQuestion: 'question4' },
            { text: 'IT HAS BEEN BETTER DAYS', nextQuestion: 'question5' },
        ],
      },
      question3: {
        question: 'DO YOU WANT TO STAY HERE?',
        imagesSet: 'set3',
        answers: [
            { text: 'YES', nextQuestion: 'question4' },
            { text: 'NO, I DONT LIKE YOU...',
            icon: faFaceAngry,
            nextQuestion: 'question7' },
            // PRZEKIERWOANIE DO LINKU YOUTUBE
            { text: 'CAN YOU SHOW ME SOMETHING?', nextQuestion: 'question4' },
        ],
      },
      question4: {
        question: 'WHAT DO YOU WANT TO SEE?',
        imagesSet: 'set4',
        answers: [
            { text: 'MORE ABOUT YOU!', sectionLink: '/about' },
            // PRZEKIEROWANIE DO SEKJI ABOUT
            { text: 'SOME YOUR WORK!', nextQuestion: 'question6' },
            { text: 'I WANT TO PLAY SOMETHING', sectionLink: '/fun' },
            // PRZEKIEROWANIE DO SEKCJI FUN SNAKE
        ],
      },
      question5: {
        question: 'OH NO, MAYBE SNAKE GAME FOR BETTER DAY?',
        imagesSet: 'set5',
        answers: [
            { text: 'YES! PLEASE!', sectionLink: '/fun' },
            // PRZEKIERWOANIE DO FUN SNAKE
            { text: 'NO, NOT TODAY', nextQuestion: 'question4' },
            { text: 'TELL ME ABOUT YOURSELF', sectionLink: '/about' },
        ],
      },
      question6: {
        question: 'OFCOURSE! CHOSE WHAT YOU WANT',
        imagesSet: 'set6',
        answers: [
            { text: 'CARPENTER WEBSITE', sectionLink: '/carpenter' },
            { text: 'PHYSIOTHERAPIST WEBSITE', sectionLink: '/physiotherapist' },
            { text: 'INTERIOR DESIGNER WEBSITE', sectionLink: '/interior designer' },
        ],
      },
      question7: {
        question: 'ARE YOU SURE?',
        imagesSet: 'set7',
        answers: [
            { text: 'YES, GO AWAY...',
            icon: faFaceAngry,
            nextQuestion: 'question8' },
            { text: 'NO SORRY, SHOW ME SOMETHING', sectionLink: 'question4' },
            { text: "LET'S START CONVERSATION AGAIN", nextQuestion: 'start' },
        ],
      },
      question8: {
        question: 'OH OKAY... I CAN SEND YOU SOME MONEY FOR WAISTING YOUR TIME...',
        imagesSet: 'set8',
        answers: [
            { text: 'YES! GIVE ME THIS!',
            icon: faFaceAngry,
            externalLink: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley' },
            { text: 'NOT LESS THAN 10$...',
            icon: faFaceAngry,
            externalLink: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley' },
            // PRZEKIERWOANIE DO LINKU YOUTUBE
            { text: "THIS HAS GONE TOO FAR LET'S START AGAIN", nextQuestion: 'start' },
        ],
      },
    };
    
    const images = {
      set1: {
        img: img1a,
        img2: img1b
      },
      set2: {
        img: img2a,
        img2: img2b
      },
      set3: {
        img: img3a,
        img2: img3b
      },
      set4: {
        img: img4a,
        img2: img4b
      },
      set5: {
        img: img5a,
        img2: img5b
      },
      set6: {
        img: img6a,
        img2: img6b
      },
      set7: {
        img: img7a,
        img2: img7b
      },
      set8: {
        img: img8a,
        img2: img8b
      },
    };

    const handleAnswer = (nextQuestion, sectionLink, externalLink) => {
      setQuestionHistory([...questionHistory, currentQuestion]);

        setCurrentQuestion(nextQuestion);
        

      
      if (sectionLink) {
        // Przekierowanie do konkretnej sekcji w aplikacji (wykorzystując Link)
        window.location.href = sectionLink;
      } else if (externalLink) {
        // Przekierowanie do zewnętrznego linku (wykorzystując element a)
        window.location.href = externalLink;
      }
    
    };
    const goBack = () => {
      const prevQuestion = questionHistory[questionHistory.length - 1];
      const updatedHistory = questionHistory.slice(0, -1);
  
      setQuestionHistory(updatedHistory);
      setCurrentQuestion(prevQuestion);
    };
    const renderQuestion = () => {
        const current = conversationTree[currentQuestion];
        if (!current) {
          return <p>Brak pytania</p>;
        }
    
        const { img, img2 } = images[current.imagesSet];
      
      


    
    return (
        
        
        <div className='content_container_home'>
          <div className='back_icon' style={{ display: currentQuestion === 'start' ? 'none' : 'block' }}
        onClick={currentQuestion !== 'start' ? goBack : null}>
          <FontAwesomeIcon className='icon_left' icon={faChevronLeft} />
          </div>
            <img src={img} alt="First background"></img>
           
            <div className='background_container'>
            <img src={img2} alt="Second background"></img>
                <div className='leading_text_container'>
    
                        <h1>{current.question}</h1>
                        <p>CLICK YOUR ANSWER!</p>
                    
                </div>
                <div className='answers_container'>
                {current.answers.map((answer, index) => (
                    <div key={index} onClick={() =>handleAnswer(answer.nextQuestion, answer.sectionLink, answer.externalLink)} className='answers'>
                    {answer.icon && <FontAwesomeIcon icon={answer.icon} className='icon'/>}
                       <p >{answer.text}</p>
                       
                    </div>
                    ))}
                </div>
            </div>
        </div>
        
        
    )
};
return (
    <div>
      {renderQuestion()}
    </div>
  );
}

export default Home;
import React from "react";
import Main from "./components/MainComponent";
// import { Routes, Route } from 'react-router-dom';
// import ScrollToTop from 'react-router-scroll-top';
import './App.css'


function App() {

  return (
    
    
    <div className="App">
      <Main/>
    </div>
    
  );
}
export default App;

import React, { useState, useRef, useEffect } from "react";
import { useInterval } from "./useInterval";
import {
  CANVAS_SIZE,
  SNAKE_START,
  APPLE_START,
  SCALE,
  SPEED,
  DIRECTIONS
} from "./constants";

import './styleCSS/Fun.css'
import './styleCSS/fonts_variables.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkull, faCaretUp, faCaretRight, faCaretDown, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
function Fun() {
    const [score, setScore] = useState(0);
    



    const canvasRef = useRef();
    const [snake, setSnake] = useState(SNAKE_START);
    const [apple, setApple] = useState(APPLE_START);
    const [dir, setDir] = useState([0, -1]);
    const [speed, setSpeed] = useState(null);
    const [gameOver, setGameOver] = useState(false);
  
    useInterval(() => gameLoop(), speed);
  
     const endGame = () => {
      setSpeed(null);
      setGameOver(true);
    };
  
    const moveSnake = ({ keyCode }) =>
      keyCode >= 37 && keyCode <= 40 && setDir(DIRECTIONS[keyCode]);

      const moveSnakeMobile = (keyCode) => {
        setDir(DIRECTIONS[keyCode]);
      };
  
    const createApple = () =>
      apple.map((_a, i) => Math.floor(Math.random() * (CANVAS_SIZE[i] / SCALE)));
  
    const checkCollision = (piece, snk = snake) => {
      if (
        piece[0] * SCALE >= CANVAS_SIZE[0] ||
        piece[0] < 0 ||
        piece[1] * SCALE >= CANVAS_SIZE[1] ||
        piece[1] < 0
      )
        return true;
  
      for (const segment of snk) {
        if (piece[0] === segment[0] && piece[1] === segment[1]) return true;
      }
      return false;
    };
  
    const checkAppleCollision = newSnake => {
      if (newSnake[0][0] === apple[0] && newSnake[0][1] === apple[1]) {
        let newApple = createApple();
        while (checkCollision(newApple, newSnake)) {
          newApple = createApple();
        }
        setApple(newApple);
        setScore(score + 1); // Zwiększenie wyniku o 1
        return true;
      }
      return false;
    };
    
    
  
    const gameLoop = () => {
      const snakeCopy = JSON.parse(JSON.stringify(snake));
      const newSnakeHead = [snakeCopy[0][0] + dir[0], snakeCopy[0][1] + dir[1]];
      snakeCopy.unshift(newSnakeHead);
      if (checkCollision(newSnakeHead)) endGame();
      if (!checkAppleCollision(snakeCopy)) snakeCopy.pop();
      setSnake(snakeCopy);
    };
  
    const startGame = () => {
      setSnake(SNAKE_START);
      setApple(APPLE_START);
      setDir([0, -1]);
      setSpeed(SPEED);
      setGameOver(false);
      setScore(0);
    };
  
    useEffect(() => {
      const context = canvasRef.current.getContext("2d");
      context.setTransform(SCALE, 0, 0, SCALE, 0, 0);
      context.clearRect(0, 0, window.innerWidth, window.innerHeight);
      context.fillStyle = "green";
      snake.forEach(([x, y]) => context.fillRect(x, y, 1, 1));
      context.fillStyle = "yellow";
      context.fillRect(apple[0], apple[1], 1, 1);
    }, [snake, apple, gameOver]);
    return (
        <React.Fragment>
        
        <div className='content_container_fun'>
        <div className="snake_container" role="button" tabIndex="0" onKeyDown={e => moveSnake(e)}>
      <p className="score">SCORE: {score} <span>MY BEST SCORE IS 53 :)</span></p>
      <div className="snake_play_filed">
      <canvas className="snake_game"
        style={{ border: "5px solid var(--bright_color)" }}
        ref={canvasRef}
        width={`${CANVAS_SIZE[0]}px`}
        height={`${CANVAS_SIZE[1]}px`}
      />
      {gameOver && <div className="game_over_title">
        <p>GAME OVER!</p>
        <p className="skull"><FontAwesomeIcon icon={faSkull}></FontAwesomeIcon></p>
        </div>}
        </div>
      <button className="start_btn" onClick={startGame}>Start Game</button>
    </div>
    <div className="keyboard_mobile">
        <div className="center_btn_snake">
          <div  className="snake_text">
          <p>SNAKE</p>
          <p>GMAE</p>
          </div>
          <div className="stearing_btn top_btn" onClick={() => moveSnakeMobile(38)}>
            <FontAwesomeIcon icon={faCaretUp}></FontAwesomeIcon>
          </div>
          <div className="stearing_btn right_btn" onClick={() => moveSnakeMobile(39)}>
          <FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>
          </div>
          <div className="stearing_btn bottom_btn" onClick={() => moveSnakeMobile(40)}>
          <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
          </div>
          <div className="stearing_btn left_btn" onClick={() => moveSnakeMobile(37)}>
          <FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon>
          </div>
        </div>
      </div>
        </div>
        
        </React.Fragment>
    )
}
export default Fun;
import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import { Squeeze as Hamburger } from 'hamburger-react'


import  './styleCSS/Header.css'
import './styleCSS/fonts_variables.css'
import './styleCSS/subdomensbtn.css'



import homeimg from './images/home.png'
import aboutimg from './images/about.png'
import funimg from './images/fun.png'
import carpenterimg from './images/carpenter.png'
import interiorimg from './images/interior.png'
import fizjoimg from './images/fizjo.png'
import nsaimg from './images/nsa.png'


function Header() {

    const [isOpen, setOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [text, setText] = useState('');
    const [pageWidth, setPageWidth] = useState(window.innerWidth);


    const originalText = '  HELLO WORLD! NICE TO SEE YOU HERE!';
    const delay = 100;

    useEffect(() => {
        const updateWidth = () => {
          const width = window.innerWidth;
          setPageWidth(width);
    
          if (width > 810) {
            setIsActive(true);
            setOpen(true);
          } else {
            setIsActive(false);
            setOpen(false);
          }
        };
    
        window.addEventListener('resize', updateWidth);
    
        return () => {
          window.removeEventListener('resize', updateWidth);
        };
      }, []);

    useEffect(() =>  {
      let currentIndex = 0;
      let textInterval = null;
  
      const piszTekst = () => {
        textInterval = setInterval(() => {
          if (currentIndex < originalText.length-1) {
            setText((prevText) => prevText + originalText[currentIndex]);
            currentIndex++;
          } else {
            clearInterval(textInterval);
            currentIndex = 0;
          }
        }, delay);
      };
  
      const resetujTekst = () => {
        clearInterval(textInterval);
        setText('');
        currentIndex = 0;
      };

      if(pageWidth > 810 && isActive) {
        resetujTekst();
        setText(originalText)
      }else {
      if (isActive) {
        resetujTekst();
        piszTekst();
      } else {
        resetujTekst();
      }
    }
  
      return () => {
        resetujTekst();
      };
    }, [isActive, originalText, delay. pageWidth]);
  
    const handleClick = () => {
      setOpen(!isOpen);
      setIsActive(!isActive);
    };

    
  

  
    

   
   
    

    

   
   
   

    

    return (
        <div className='container'>
            <div className={`name_description ${isActive ? 'active' : ''}`}>
                <div className='name_description_box'>
               
                <div className='name_text'> 
                <Link to='/home'><h1 className={`name_text ${isActive ? 'active' : ''}`}>NORBERT PRUSIK</h1></Link>
                <div className='hello_text_header'>
                          {pageWidth > 810 ? (
                          <p>{originalText}</p>
                         ) : (
                         <p className={`welcome_title${isActive ? 'active' : ''}`}>{text} <span className='marker'>|</span> </p>
                    )}</div>
                
                
                    </div>
                    <div className='closing_tag' onClick={handleClick}>
                    <Hamburger 
                    color="#c7c3ab"
                    size={35}
                    toggled={isOpen} toggle={setOpen} />
                    </div>
                </div>
                
                    
            </div>
        <div className={`main_container ${isActive ? 'active' : ''}`}> 
            
            <div className='hello_container'>
                <Link to='/home'>
                    <div className='home_btn subdomen' onClick={handleClick}>
                        <img src={homeimg} alt=''></img>
                        <div className='home_txt'>
                            <h1>Home</h1>
                            <p>HI! WELCOME ON MY SITE!</p>
                        </div> 
                    </div>
                </Link>



                <Link to='/fun'>
                    <div className='fun_btn subdomen' onClick={handleClick}>
                        <img src={funimg} alt=''></img>
                        <div className='fun_txt'>
                            <h1>Some fun</h1>
                            <p>DO YOU WANNA PLAY SOMETHING?</p>
                        </div> 
                    </div>
                </Link>



                <Link to='/about'>
                    <div className='about_btn subdomen' onClick={handleClick}>
                        <img src={aboutimg} alt=''></img>
                        <div className='about_txt'>
                            <h1>About</h1>
                            <p>GET MORE ABOUT ME</p>
                        </div> 
                    </div>
                </Link>
                </div>


                <div className='projects_container'>

                    <div className='projects_title'>
                        <h1>CHOSEN PROJECTS</h1>
                    </div>

                <Link to='/carpenter'>
                    <div className='carpenter_btn subdomen' onClick={handleClick}>
                        <img src={carpenterimg} alt=''></img>
                        <div className='carpenter_txt'>
                            <h1>Adrem Meble</h1>
                            <p>CARPENTER</p>
                        </div> 
                    </div>
                </Link>



                <Link to='/interior designer'>
                    <div className='interior_btn subdomen' onClick={handleClick}>
                        <img src={interiorimg} alt=''></img>
                        <div className='interior_txt'>
                            <h1>Bracia Pruisik</h1>
                            <p>INTERIOR DESIGNER</p>
                        </div> 
                    </div>
                </Link>


                
                <Link to='/physiotherapist'>
                    <div className='fizjo_btn subdomen' onClick={handleClick}>
                        <img src={fizjoimg} alt=''></img>
                        <div className='fizjo_txt'>
                            <h1>AS Fizjoterapia</h1>
                            <p>PHYSIOTERAPIST</p>
                        </div> 
                    </div>
                </Link>


                <Link to='/nsa'>
                    <div className='nsa_btn subdomen' onClick={handleClick}>
                        <img src={nsaimg} alt=''></img>
                        <div className='nsa_txt'>
                            <h1>No Stress Academy</h1>
                            <p>JOGA TEACHER</p>
                        </div> 
                    </div>
                </Link>
                </div>
            
        </div>
        </div>
    )
}
export default Header;


  
import React from 'react'

import './styleCSS/Carpenter.css'
import bgcOpening from './images/adrembgcblur.png'
import logoAdrem from './images/logoadrem.png'
import textAdrem from './images/textadrem.png'
import graphic1 from './images/grafika1 got.png'
import videofunction from './images/desktopfuncQ.mp4'
import videodesktopexp from './images/desktopexpQ.mp4'
import videomobileexp from './images/mobileexpQ.mp4'
function Carpenter() {

    
    return (
        <React.Fragment>
        
        <div className='content_container_carpenter'>
            <div className='see_site_btn'>
                <a href='http://adremmeble.pl/'><p>VISIT SITE</p></a>
            </div>
            <div className='opening'>
                <div className='photo_bgc'>
                    <img className='bgc' src={bgcOpening}></img>
                    <div className='logo_name'>
                        <img className='logo' src={logoAdrem}></img>
                        <img className='text' src={textAdrem}></img>
                    </div>
                    <div className='opening_description'>
                        <div className='opening_description_text'>
                        <h1>ADREM MEBLE</h1>
                        <p>A WEBSITE THAT ALLOWS THE CUSTOMER TO LOOK AT THE CARPENTER'S REALIZATIONS AND HELPS THEM FIND THEIR OWN INTERIOR DESIGN STYLE
                        </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='summary'>
                <div className='summary_box'>
                    <div className='summary_text_box'>
                        <h1>SUMMARY</h1>
                        <p>THE DESIGN OF THE ADREM MEBLE WEBSITE IS MY ORIGINAL IDEA, THE CLIENT HAD AN OLD WEBSITE, BUT WANTED A FRESH NEW LOOK 
                        </p>
                    </div>
                    <div className='summary_text_box'>
                        <h1>ASSUMPTIONS</h1>
                        <p>WIDE OPEN SPACE WITH CLASSIC LOOK, GOOD PRESENTATION OF REALIZATIONS AND POSSIBILITY TO FIND OWN INTERIOR STYLE</p>
                    </div>
                    <div className='summary_text_box'>
                        <h1>CLIENT</h1>
                        <p>ADREM MEBLE - CARPENTER FROM NORTH MAZOVIA</p>
                    </div>
                </div>
            </div>
            <div className='about_website'>
                <div className='about_website_box'>
                    <div className='about_website_img_box'>
                        <img src={graphic1}></img>
                        
                    </div>
                    <div className='about_website_text_box'>
                        <h1>MORE ABOUT WEBISTE</h1>
                        <p>YOU CAN FIND HERE FEW DIFERENT SECTIONS, YOU CAN LOOK AT CURRNET REALIZATION BY SLIDER OR OPEN ALL GALLERY. IF YOU DONT KNOW WHAT YOU LIKE, YOU CAN CHECK SECTION INSPIRATIONS WHERE YOU FIND SEVEN DIFERENT INTERIOR STYLES WITH POSSIBILITY TO SEE HOW LOOK EVERY TYPE OF ROOM IN CHOOSEN STYLE</p>
                    </div>
                </div>
            </div>
            <div className='main_function'>
                <div className='main_function_box'>
                    <div className='main_function_video_box'>
                        <video  src={videofunction} autoPlay loop muted></video>
                    </div>
                    <div className='main_function_text_box'>
                        <h1>INTERESITING FUNCTION</h1>
                        <p>CHECK THE INTERIOR STYLES AND FIND OUT WHAT YOU LIKE! EASY-TO-USE SLIDER IN SLIDER WITH 7 VARIOUS STYLES AND 5 DIFERENT TYPES OF ROOMS IN EACH OF THEM. INDUSTRIAL, MINIMALISM AND SKANDYNAVIAN ARE ONLY FEW OF THEM </p>
                    </div>
                </div>
            </div>
            <div className='user_experience'>
                <div className='user_experience_box'>
                   
                    <div className='user_experience_video user_experience_mobile'>
                        <h1>MOBILE USER EXPERIENCE</h1>
                    <video src={videomobileexp} autoPlay loop muted></video>
                    </div>
                    <div className='user_experience_video user_experience_desktop'>
                        <h1>DEKSTOP USER EXPERIENCE</h1>
                    <video src={videodesktopexp} autoPlay loop muted></video>
                    </div>
                </div>
            </div>
        </div>
        
        </React.Fragment>
    )
}
export default Carpenter;
import React, {useState} from 'react'
import copy from 'clipboard-copy'; 
import SpotifyWebApi from 'spotify-web-api-js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faChevronDown, faArrowRightLong, faEnvelope, faFilePdf, faLink, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FaYoutube, FaTiktok, FaInstagram, FaHtml5, FaCss3, FaJs, FaReact, FaLinkedinIn, FaGithub } from 'react-icons/fa';



import './styleCSS/About.css'
import './styleCSS/fonts_variables.css'
import about1 from './images/about_images_vid/about9.png'
import about2 from './images/about_images_vid/about4.mp4'
import about3 from './images/about_images_vid/about7.png'
import about4 from './images/about_images_vid/about1.png'
import about5 from './images/about_images_vid/about10.mp4'
import about6 from './images/about_images_vid/about5.png'
import about7 from './images/about_images_vid/about8.mp4'

import dale from './images/dale.jpg'
import deepak from './images/deepak.jpg'
import dan from './images/dan.jpg'



const slides = [
    { type: 'image', content: about1, text: 'HELLO THERE' },
    { type: 'video', content: about2, text: '*BALANCE IN PROCESS*' },
    { type: 'image', content: about3, text: 'ME IN RIO DE JANEIRO' },
    { type: 'image', content: about4, text: 'MODELING TIME' },
    { type: 'video', content: about5, text: 'CHILLIN ON POLISH ROADS' },
    { type: 'image', content: about6, text: 'REFRESHING ICE BATH' },
    { type: 'video', content: about7, text: 'MY DOG HAS GROWN A BIT' },
  ];
function About() {

    const spotifyApi = new SpotifyWebApi();
    spotifyApi.setAccessToken('ef65c66cbe5441ada028f3144ccd4898');
    
  

    const [activeIndexes, setActiveIndexes] = useState(new Array(4).fill(false));

    const showDateSection = (index) => {
        const newActiveIndexes = activeIndexes.map((isActive, i) => (i === index ? !isActive : false));
        setActiveIndexes(newActiveIndexes);
    }

    const [activeElement, setActiveElement] = useState(null);

  const handleClick = (element) => {
    if (activeElement === element) {
      setActiveElement(null);
    } else {
      setActiveElement(element);
    }
  };

      const [currentSlide, setCurrentSlide] = useState(0);
      
    
      const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
         
      };
    
      const handleNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
        
      };
    
 
    
    
    
      const renderSlide = (slide, index) => {
   
        if (slide.type === 'image') {
            return (
                <div className='slide_content'>
                  <img src={slide.content} alt={`Slide ${currentSlide}`} />
                
                </div>
              );
        } else if (slide.type === 'video') {
            return (
                <div className='slide_content'>
                  <video src={slide.content} autoPlay loop muted />
                 
                </div>
              );
        }
        return null;
      };


    
      const [isActive, setIsActive] = useState(false);
      const emailToCopy = "norbertprusik77@gmail.com"; 
    
      const copyText = async () => {
        await copy(emailToCopy);
        setIsActive(true);
        setTimeout(() => {
          setIsActive(false);
        }, 1000); 
      };

    

    //   const showBook = (index) => {
    //       const newActiveIndexes = activeIndexes.map((isActive, i) => (i === index ? !isActive : false));
    //       setActiveIndexes(newActiveIndexes);
    //   }


    return (
        <React.Fragment>
        
        <div className='content_container_about'>
            <div className='photo_description_container'>
                <div className='photo_description_element'>
                    <div className='photo_element'>
                    <div className="slide"> {renderSlide(slides[currentSlide])}
                    <div className='shadow_element_text'>
                            <p>{slides[currentSlide].text}</p>
                        </div>
                        </div>

                        <div className='arrows'>
                            <button className='arrow_left arrow_btn' onClick={handlePrevSlide}>
                            <FontAwesomeIcon className='icon_left' icon={faChevronLeft} />
                            </button>
                            <button className='arrow_right arrow_btn' onClick={handleNextSlide}>
                            <FontAwesomeIcon className='icon_right' icon={faChevronRight} />
                            </button>
                        </div>
                        <div className='text_dots'>
                            <div className='dots'>
                            {slides.map((slide, index) => (
                                  <div
                                    key={index}
                                    className={`dot dot${index + 1} ${index === currentSlide ? 'active' : ''}`}
                                    onClick={() => setCurrentSlide(index)}
                                  ></div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='description_element'>
                        <h1>HI! I`M NORBERT!</h1>
                        <p className='motto'>"IF I DONT KNOW SOMETHING YET, GIVE ME YOUTUBE ACCES AND FEW MINUTS"</p>
                        <p>I STARTED CODING IN FEBUARY 2023 TO BECOME A PROGRAMMER. AT THE MOMENT MY FOCUS IS ON FRONTEND BUT I WOULD LIKE TO LEARN BACKEND WORLD. I WOULD DESCRIBE MYSELF AS CREATIVE, DETERMINED PROBLEM SOLVER WITH STRONG ANALYTICAL MIND AND CRITICAL THINKING WHO IS READY FOR ANY NEW CHALLANGES THAT COME MY WAY :) </p>
                    </div>
                </div>
            </div>
            <div className='specific_informations_container'>
                <div className='experience info_boxes'>
                    <h1 className='title_list'>EXPERIENCE</h1>


                    <div className={`date_box date_one ${activeIndexes[0] ? 'active' : ''}`} onClick={() => showDateSection(0)}>
                    <div className='date_text_box'>
                        <h2 className='date'>AUG.2020 - MAY.2022</h2>
                        <div className='box_icon_down'>
                            <FontAwesomeIcon className={`icon_down ${activeIndexes[0] ? 'active' : ''}`} icon={faChevronDown}></FontAwesomeIcon>
                            </div>
                    </div>
                    <ul className='first_date'>
                        <li>
                            <p>HIGHSCHOOL EDUCATION 2019-2022 </p>
                        </li>
                        <li>
                            <p>CREATION OF INTERIOR DESIGN OFFICE "BRACIA PRUSIK" WITH BROTHER</p>
                        </li>
                        <li>
                            <p>SOCIALMEDIA BRANDING 'PRUSIK BROTHERS' ON FACEBOOK, INSTAGRAM AND TIKTOK</p>
                        </li>
                        <li className='linked_p'>
                            <p>THIS IS ONE OF OUR PRODUCTIONS 
                            <a className='link_experience' href='https://www.youtube.com/watch?v=tD88vorqpH0&ab_channel=Prus77'>
                            <p><FontAwesomeIcon className='icon_long_arrow_right icons_experience' icon={faArrowRightLong} />
                            <FaYoutube className='icon_youtube icons_experience' />"SHU SUGI BAN DESK"
                            </p>
                            </a>
                            </p>
                        </li>               
                    </ul>
                    </div>

                    <div className={`date_box date_two ${activeIndexes[1] ? 'active' : ''}`} onClick={() => showDateSection(1)}>
                    <div className='date_text_box'><h2 className='date'>JUN.2022 - OCT.2022</h2>
                    <div className='box_icon_down'>
                            <FontAwesomeIcon className={`icon_down ${activeIndexes[1] ? 'active' : ''}`} icon={faChevronDown}></FontAwesomeIcon>
                            </div>
                    </div>
                    <ul>
                        <li>
                            <p>RUNNING MY OWN BUSINESS AS A PERSONAL TRAINER</p>
                        </li>
                        <li>
                            <p>COMPLETION OF A TWO-WEEK COURSE IN PERSONAL TRAINER SPECIALIZATION IN WARSAW </p>
                        </li>
                        <li>
                            <p>CONDUCTING TRAINING AND CARE FOR 10 PEOPLE AT THE SAME TIME </p>
                        </li>
                        <li>
                            <p>CREATE SOCIAL MEDIA BRAND 'MOCNY TRENER' ON TIKTOK AND INSTAGRAM</p>
                            <a className='link_experience' href='https://www.tiktok.com/@mocnytrener'><p><FontAwesomeIcon className='icon_long_arrow_right icons_experience' icon={faArrowRightLong} />
                            <FaTiktok className='icon_tiktok icons_experience' />@mocnytrener
                            </p>
                            </a>
                            <a className='link_experience' href='https://www.instagram.com/mocnytrener/'><p><FontAwesomeIcon className='icon_long_arrow_right icons_experience' icon={faArrowRightLong} />
                            <FaInstagram className='icon_instagram icons_experience' />@mocnytrener
                            </p>
                            </a>
                            
                            
                        </li>              
                    </ul>
                    </div>
                    <div className={`date_box date_three ${activeIndexes[2] ? 'active' : ''}`} onClick={() => showDateSection(2)}>
                    <div className='date_text_box'><h2 className='date'>OCT.2022 - APR.2023</h2>
                    <div className='box_icon_down'>
                            <FontAwesomeIcon className={`icon_down ${activeIndexes[2] ? 'active' : ''}`} icon={faChevronDown}></FontAwesomeIcon>
                            </div>
                    </div>
                    <ul>
                        <li>
                            <p>TAKING A JOB AT LOCAL GYM XTREME FITNESS GYMS AS A GYM INSTRUCTOR</p>
                        </li>
                        <li>
                            <p>CREATION AND MAINTENANCE OF A TIKTOK ACCOUNT FOR XTREME FITNESS GYMS MŁAWA DOING HUNDREDS OF THOUSANDS OF VIEWS</p>
                            <a className='link_experience' href='https://www.tiktok.com/@xfg_mlawa'> <p> <FontAwesomeIcon className='icon_long_arrow_right icons_experience' icon={faArrowRightLong} />
                            <FaTiktok className='icon_tiktok icons_experience' />@xfg_mlawa
                            </p></a>
                        </li>
                        <li>
                            <p>FALLING IN LOVE WITH PORGRAMMING</p>
                        </li> 
                        
                        
                        <li>
                            <p>LAUNCH OF FIRST PROGRAMMING COURSE IN FEBRUARY 2023</p>
                        </li>                             
                    </ul>
                    </div>
                    <div className={`date_box date_four ${activeIndexes[3] ? 'active' : ''}`} onClick={() => showDateSection(3)}>
                    <div className='date_text_box'><h2 className='date'>MAY.2023 - NOW</h2>
                    <div className='box_icon_down'>
                            <FontAwesomeIcon className={`icon_down ${activeIndexes[3] ? 'active' : ''}`} icon={faChevronDown}></FontAwesomeIcon>
                            </div>
                    </div>
                    <ul>
                        <li>
                            <p>BACK TO INTERIOR DESIGN AND SPLITTING TIME 50/50 WITH PROGRAMMING</p>
                        </li>
                        <li>
                            <p>CO-AUTHORING TIKTOK ACCOUNT TO PROMOTE BUIRO INTERIOR DESIGN</p>
                            <a className='link_experience' href='https://www.tiktok.com/@yogin.interiordesigner'> <p> <FontAwesomeIcon className='icon_long_arrow_right icons_experience' icon={faArrowRightLong} />
                            <FaTiktok className='icon_tiktok icons_experience' />@yogin.interiordesigner
                            </p></a>
                        </li>
                        <li>
                            <p>RECEIPT OF FIRST ORDERS FOR WEBSITES *YOU CAN SEE THEM IN THE MENU*</p>
                        </li>
                                                    
                    </ul>
                    </div>
                </div>

                <div className='skills info_boxes'>
                    <h1 className='title_list '>SKILLS</h1>
                    <ul className='skills_container'>
                        <li>
                            <p>SELLING SKILLS</p>
                        </li>
                        <li>
                            <p>INTERPERSONAL COMUNICATION</p>
                        </li>
                        <li>
                            <p>PROJECT MANAGMENT</p>
                        </li>
                        <li>
                            <p>ABILITY TO DEVELOP SOCIALMEDIA BRANDS</p>
                        </li>
                        <li>
                            <p>CREATION OF POPULAR TIKTOK ACCOUNTS</p>
                        </li>
                        <li>
                            <p>CREATIVITY AND PERSEVERANCE AT A HIGH LEVEL</p>
                        </li>
                        <li>
                            <p>LEADERSHIP AND PEOPLE MANAGEMENT SKILLS</p>
                        </li>
                        <li>
                            <p>ABILITY TO MAKE DIFFICULT DECISIONS</p>
                        </li>
                        <li>
                            <p>ANATLITIC MIND</p>
                        </li>
                        <li>
                            <p>EXCELENCE SENSE OF HUMOR</p>
                        </li>                   
                    </ul>
                </div>
                <div className='tools info_boxes'>
                    <h1 className='title_list'>TECHNOLOGIES</h1>
                    <div className='tech_lines_container'>  
                    
                    <div className='tech_icons_container'>      
                    <div className='tech_box_one'>        
                        <div className={`tech_icon_container html_container ${activeElement === 'html' ? 'active' : ''}`}
                         onClick={() => handleClick('html')}>
                             <FaHtml5 className={`tech_icon ${activeElement === 'html' ? 'active' : ''}`}></FaHtml5> 
                             </div>
                        <div  className={`tech_icon_container css_container ${activeElement === 'css' ? 'active' : ''}`}
                         onClick={() => handleClick('css')}>
                             <FaCss3 className={`tech_icon ${activeElement === 'css' ? 'active' : ''}`}></FaCss3> 
                             </div>                
                        <div className={`tech_icon_container js_container ${activeElement === 'js' ? 'active' : ''}`}
                        onClick={()=> handleClick('js')}>
                             <FaJs className={`tech_icon ${activeElement === 'js' ? 'active' : ''}`}></FaJs> 
                             </div>
                        <div className={`tech_icon_container react_container ${activeElement === 'react' ? 'active' : ''}`}
                        onClick={()=> handleClick('react')}> 
                        <FaReact className={`tech_icon ${activeElement === 'react' ? 'active' : ''}`}></FaReact> 
                        </div>
                    </div>  
                    <div className='tech_box_two'>
                        <div className={`description_tech_element description_html ${activeElement === 'html' ? 'active' : ''}`}>
                            <p>THE FIRST TIME I SAW HTML I THOUGHT IT WAS PROGRAMMING AND THAT IT WAS VERY EASY HAHA :D I STILL DIDN'T KNOW WHAT AWAITED ME WHILE LEARNING JAVA SCRIPT...
                            </p>
                        </div>
                        <div className={`description_tech_element description_css ${activeElement === 'css' ? 'active' : ''}`}>
                            <p>CSS IS MY GOOD FRIEND, SINCE THE SAME START OF OUR RELATIONSHIP WE LIKED EACH OTHER, AND LIKE
                                IN REAL RELATIONSHIP WE ARGUING PRETTY OFTEN
                            </p>
                        </div>
                        <div className={`description_tech_element description_js ${activeElement === 'js' ? 'active' : ''}`}>
                            <p>AFTER A FEW MONTHS WITH HTML AND CSS I STARTED A JAVA SCRIPT COURSE AND LOVED IT! I LOVED THE ABILITY TO CREATE ANYTHING I CAN THINK OF FROM A SIMPLE BUTTON FUNCTION TO A WHOLE MINI GAME </p>
                        </div>
                        <div className={`description_tech_element description_react ${activeElement === 'react' ? 'active' : ''}`}>
                            <p>MANY PEOPLE TOLD ME NOT TO TOUCH FRAMEWORKS BUT I WAS CAPTIVATED BY THE EFFECT OF SINGLE PAGE APLICATION. SO I STARTED TO MAKE MY PROTFOLIO SITE TOGETHER WITH REACT
                            </p>
                        </div>
                        </div>
                    </div> 
                    
                        
                    <div className='loading_bar_technologies'>
                        <h2>LOADING NEXT SKILLS 
                            <span className='tech_dot tech_dot_one'> .</span>
                            <span className='tech_dot tech_dot_two'> .</span>
                            <span className='tech_dot tech_dot_three'> .</span>
                             </h2>
                        <div className='progress_bar'>
                            <div className='bar_border'>
                                <div className='bar_line'></div>
                                <div className='bar_dots_container'>
                                <div className='bar_dots bar_dot_one'></div>
                                <div className='bar_dots bar_dot_one'></div>
                                <div className='bar_dots bar_dot_one'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className='contact info_boxes'>            
                    <h1>CONTACT</h1>
                    <div className='icon_container_contact'>
                        <div className={`copied_sign ${isActive ? 'active' : ''}`}>
                            <p>COPIED!</p>
                        </div>
                        <div className='icon_box_contact contact_tabel'>
                        <a className='side_element_contact' href='https://www.linkedin.com/in/norbert-prusik-048910287/'> <FaLinkedinIn></FaLinkedinIn></a>
                        <div className='center_element_contact'> <p>NORBERT PRUSIK</p> </div>
                        <div className='side_element_contact'>
                            <div className='side_btn_contact'><a className='side_element_contact' href='https://www.linkedin.com/in/norbert-prusik-048910287/'><FontAwesomeIcon icon={faLink}></FontAwesomeIcon></a></div>
                        </div>
                        </div>
                        <div className='icon_box_contact contact_tabel'>
                        <div className='side_element_contact'>  <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></div>
                        <div className='center_element_contact'> <p>{emailToCopy}</p> </div>
                        <div className='side_element_contact'>
                            <div className='side_btn_contact' onClick={copyText}> <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon></div>
                        </div>
                        </div>
                        <div className='icon_box_contact contact_tabel'>
                        <a className='side_element_contact' href='https://github.com/prussocoderro'> <FaGithub></FaGithub></a>
                        <div className='center_element_contact'> <p>NORBERT PRUSIK - prussocoderro</p> </div>
                        <div className='side_element_contact'>
                            <div className='side_btn_contact'><a className='side_element_contact' href='https://github.com/prussocoderro'><FontAwesomeIcon icon={faLink}></FontAwesomeIcon></a></div>
                        </div>
                        </div>
                        <div className='icon_box_contact'>
                           <a className='side_element_contact' href='https://drive.google.com/file/d/13sA-oGgMpxrFqzUlWVco5Cb72d1e8bv0/view?usp=sharing'> <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon></a>
                           <div className='center_element_contact'> <p>RESUME/CV</p> </div>
                        <div className='side_element_contact'>
                            <div className='side_btn_contact'><a className='side_element_contact' href='https://drive.google.com/file/d/1pO0o7P2Ps9EgR2NL2xOapDFsuBM_00gW/view?usp=drive_link'> <FontAwesomeIcon icon={faLink}></FontAwesomeIcon> </a><span>ENG</span></div>
                            <div className='side_btn_contact'><a className='side_element_contact' href='https://drive.google.com/file/d/1iSt0iI0NpJVSqLhgt_7BXK3-OXpBwyxr/view?usp=drive_link'> <FontAwesomeIcon icon={faLink}></FontAwesomeIcon> </a><span>PL</span></div>
                        </div>
                        </div>
                    </div>
                    
                </div>
                <div className='info_boxes'>
                    <div className='spotify_title'><h1>THIS IS WHAT MY PROGRAMMING SOUNDS LIKE </h1></div>
                    <div className='spotify_container'>                    
                          <iframe title='API spotify' className='spotify' src="https://open.spotify.com/embed/playlist/1cxzSlxLmW2wu7ic5RmlpB?utm_source=generator" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" ></iframe>
                          
                     </div>
                </div>
                <div className='info_boxes'>
                    <div className='books_title'>
                        <h1>SOME TOP BOOKS</h1>
                    </div>
                    <div className='books_container'>
                        <div className={`book_des_box ${activeIndexes[0] ? 'active' : ''}`} onClick={() => showDateSection(0)}>
                        <div className='book_box'>
                            <div className='book'>
                                <img alt='Dale Carnegie - How To Win Friends & Influence People' src={dale}></img>
                                <div className='description_btn'>
                                    <p>SHOW DESCRIPTION</p>
                                </div>
                            </div>
                            <div className='book_description'>
                                <div className='back_book_btn '>
                                    <p>BACK TO COVER</p>
                                </div>
                                <div className='description_text_container'>
                                    <p className='description_text'>In this book Carnegie, offers practical advice and techniques, in his exuberant and conversational style, for how to get out of a mental rut and make life more rewarding. His advice has stood the test of time and will teach you how to: make friends quickly and easily, persuade people to follow your way of thinking, enable you to win new clients and customers, become a better speaker,boost enthusiasm among your colleagues</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className={`book_des_box book_des_box_left ${activeIndexes[1] ? 'active' : ''}`} onClick={() => showDateSection(1)}>
                        <div className='book_box book_box_left'>
                            <div className='book book_left'>
                                <img alt='Deepak Chopra - The Spontaneous Fulfillment Of Desire' src={deepak}></img>
                                <div className='description_btn description_btn_left'>
                                    <p>SHOW DESCRIPTION</p>
                                </div>
                            </div>
                            <div className='book_description'>
                            
                                <div className='description_text_container'><p className='description_text'>From this realm of pure potential we are connected to everything that exists and everything that is yet to come. "Coincidences" can then be recognized as containing precious clues about particular facets of our lives that require our attention. As you become more aware of coincidences and their meanings, you begin to connect more and more with the underlying field of infinite possibilities. </p>
                                </div>
                                <div className='back_book_btn back_book_btn_left'>
                                    <p className='text_back_left'>BACK TO COVER</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className={`book_des_box ${activeIndexes[2] ? 'active' : ''}`} onClick={() => showDateSection(2)}>
                        <div className='book_box'>
                            <div className='book '>
                                <img alt='Dan Brown - The Da Vinci Code' src={dan}></img>
                                <div className='description_btn'>
                                    <p>SHOW DESCRIPTION</p>
                                </div>
                            </div>
                            <div className='book_description'>
                            <div className='back_book_btn'>
                                    <p>BACK TO COVER</p>
                                </div>
                                <div className='description_text_container'><p className='description_text'>The Louvre, Paris: the elderly curator of the museum has been violently murdered in the Grand Gallery. Harvard professor Robert Langdon is summoned to decipher the baffling codes which the police find alongside the body. As he and a gifted French cryptologist, Sophie Neveu, sort through the bizarre riddles, they are stunned to find a trail that leads to the works of Leonardo Da Vinci - and suggests the answer to an age-old mystery that stretches deep into the vault of history.</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}
export default About;
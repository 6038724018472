import React from 'react'

import './styleCSS/Nsa.css'
function Nsa() {
    return (
        <React.Fragment>
        
        <div className='content_container_nsa'>
        <div className='border'>
            <h2 className='website_name'>NO STRESS ACADEMY - MAKE LIFE BETTER</h2>
                <h1>AVAIBLE SOON</h1>
                <p>THE WEBISTE IS STILL BUILDING BUT YOU CAN COME BACK HERE
                    SOON
                </p>
            </div>
            <div className='loading_bar_building'>
                        <h2>BUILDING 
                            <span className='tech_dot tech_dot_one'> .</span>
                            <span className='tech_dot tech_dot_two'> .</span>
                            <span className='tech_dot tech_dot_three'> .</span>
                             </h2>
                        <div className='progress_bar'>
                            <div className='bar_border'>
                                <div className='bar_line'></div>
                                <div className='bar_dots_container'>
                                <div className='bar_dots bar_dot_one'></div>
                                <div className='bar_dots bar_dot_one'></div>
                                <div className='bar_dots bar_dot_one'></div>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
        
        </React.Fragment>
    )
}
export default Nsa;
import React from "react";
import Header from './HeaderComponent'
// import Footer from './FooterComponent'

import Home from './HomeComponent'
import About from './AboutComponent'
import Fun from './FunComponent'
import Carpenter from './CarpenterComponent'
import Interior from './InteriorComponent'
import Fizjo from './FizjoComponent'
import Nsa from './NsaComponent'

import './styleCSS/Main.css'

import {Routes, Route, Navigate, BrowserRouter as Router} from 'react-router-dom'




function Main() {

  return (
  
    
  <Router>

  
<div className="header"><Header/></div>
    <div className='subdomens_container'>
    <Routes>
       <Route path='/home' element={<Home />}/>
       <Route path='/about' element={<About />}/>
       <Route path='/fun' element={<Fun />}/>
       <Route path='/carpenter' element={<Carpenter />}/>
       <Route path='/interior designer' element={<Interior />}/>
       <Route path='/physiotherapist' element={<Fizjo />}/>
       <Route path='/nsa' element={<Nsa />}/>
       <Route path='*' element={<Navigate to="/home" />} />
    </Routes>
    </div>
    {/* <div className="footer"><Footer/></div> */}
    
    </Router>
 
  )
}
export default Main;
